import Vue from 'vue'
import App from './App.vue'
import router from './libs/router'
import store from './store'
import '@/assets/css/font.css'
import '@/assets/css/main.css'
import Request from './libs/request'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

Vue.use(ElementUI)

import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'

// 使用swiper插件
Vue.use(VueAwesomeSwiper)

import VueMeta from 'vue-meta';

Vue.use(VueMeta);

import VueLazyload from 'vue-lazyload'
Vue.use(VueLazyload)


// 把请求方法放进Request
Vue.prototype.$request=Request

//去除存在数据库的富文本产生的p标签
Vue.prototype.$deTag=function (str) {
  return str.replace(/<[^>]*>/g, '');
}

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
