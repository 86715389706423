import axios from 'axios'
const axiosService = axios.create({
  baseURL: "/ht/",
  timeout: 10000
})

axiosService.interceptors.request.use(
  config => {
      /**
       * TODO
       * Configuration before request
       */
      // console.log(config)
      return config
  },
  error => {
      /**
       * TODO
       * do some error handling
       */
      console.log(error)
      return Promise.reject(error)
  }
)

// re request
function reRequest(err) {
  let againReq = new Promise((resolve) => {
      console.log('request:' + err.config.url + 'Request failed, re request');
      resolve();
  })
  return againReq.then(() => {
      return axiosService(err.config);
  })
}

// axios response interceptors
axiosService.interceptors.response.use(
  response => {
      const res = response.data
      /**
       * TODO
       * Data processing operation
       */
      return Promise.resolve(response)
  },
  error => {
      /**
       * TODO
       * do some error handling
       * if net error, re request
       */
      console.log(error)
      Message({
          message: error.message,
          type: "error"
      })
      return Promise.reject(error)
  }
)

export default axiosService
